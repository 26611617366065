import axios from "@/plugins/axios.plugin";

export function login(username, password) {
  return axios.post("/kanrisha/login", { username, password });
}

export function getAdmin() {
  return axios.get("/kanrisha");
}

export function updateAdmin({ name, username, password }) {
  return axios.patch("/kanrisha", { name, username, password });
}

export function getSummary() {
  return axios.get("/kanrisha/dashboard");
}

export function getTransaction(page, limit, search, status) {
  return axios.get("/kanrisha/transaction", {
    params: { page, limit, search, status },
  });
}

export function updateTransaction(id, status) {
  return axios.put(`/kanrisha/transaction/${id}`, { status });
}

export function getUser(page, limit, search, status) {
  return axios.get("/kanrisha/user", {
    params: { page, limit, search, status },
  });
}

export function updateUser(id, limit, expiredAt) {
  return axios.put(`/kanrisha/user/${id}`, { limit, expiredAt });
}
